.markdown {

  p {
    margin: auto;
    text-align: justify;
  }

  h1 {
    font-size: .8em;
    margin-top: 3em;
  }
}
